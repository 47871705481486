<template>
  <v-row>
    <loading :active.sync="loading"></loading>
    <v-card class="pa-6 mt-n8" width="100%" flat>
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="7" sm="7">
            <v-btn icon class="mt-n3" @click="cancel()">
              <v-icon> mdi-less-than </v-icon>
            </v-btn>
            <span class="text-h4"> Untitled - {{ today }} </span>
          </v-col>
          <v-col cols="12" md="2" sm="2" class="text-right mt-n1">
            <v-select
              v-model="status"
              outlined
              :items="statusItems"
              item-value="name"
              background-color="white"
              style="border-radius: 0px"
            >
              <template v-slot:selection="{ item }">
                <span v-if="item.name == 'เปิดใช้งาน'" style="color: #11c872">
                  <v-icon style="color: #11c872"> mdi-circle </v-icon>
                  <v-icon style="color: #11c872"> mdi-blank </v-icon>
                  {{ item.name }}
                </span>
                <span v-if="item.name == 'ปิดใช้งาน'" style="color: red">
                  <v-icon style="color: red"> mdi-circle </v-icon>
                  <v-icon style="color: red"> mdi-blank </v-icon>
                  {{ item.name }}
                </span>
                <span v-if="item.name == 'ฉบับร่าง'" style="color: #026aa2">
                  <v-icon style="color: #026aa2"> mdi-circle </v-icon>
                  <v-icon style="color: #026aa2"> mdi-blank </v-icon>
                  {{ item.name }}
                </span>
              </template>
              <template v-slot:item="{ item }">
                <span v-if="item.name == 'เปิดใช้งาน'" style="color: #11c872">
                  <v-icon style="color: #11c872"> mdi-circle </v-icon>
                  <v-icon style="color: #11c872"> mdi-blank </v-icon>
                  {{ item.name }}
                </span>
                <span v-if="item.name == 'ปิดใช้งาน'" style="color: red">
                  <v-icon style="color: red"> mdi-circle </v-icon>
                  <v-icon style="color: red"> mdi-blank </v-icon>
                  {{ item.name }}
                </span>
                <span v-if="item.name == 'ฉบับร่าง'" style="color: #026aa2">
                  <v-icon style="color: #026aa2"> mdi-circle </v-icon>
                  <v-icon style="color: #026aa2"> mdi-blank </v-icon>
                  {{ item.name }}
                </span>
              </template>
            </v-select>
          </v-col>
          <v-divider class="mt-2" vertical style="height: 56px"></v-divider>
          <v-col cols="12" md="1" sm="1" class="text-right">
            <v-btn
              text
              color="#2ab3a3"
              style="
                border: solid 1px #2ab3a3;
                border-radius: 0px;
                padding: 12px 16px;
                gap: 8px;
                width: 114px;
                height: 48px;
              "
              >บันทึกฉบับร่าง</v-btn
            >
          </v-col>
          <v-col cols="12" md="2" sm="2">
            <v-btn
              color="#2AB3A3"
              dark
              elevation="0"
              style="
                border-radius: 0px;
                padding: 12px 16px;
                gap: 8px;
                height: 48px;
              "
            >
              ยืนยันการเพิ่ม
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-col class="mt-10">
      <span
        style="
          font-family: 'IBM Plex Sans Thai';
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 40px;
        "
      >
        เพิ่ม File Download
      </span>
    </v-col>
    <v-card class="pa-6" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="2" sm="2"
            ><span class="pt-2">ชื่อหัวข้อ (TH)</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="10" sm="10">
            <v-text-field
              hide-details
              v-model="form.title"
              placeholder="ชื่อหัวข้อ (TH)"
              dense
              outlined
              :rules="rules.required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2" sm="2"
            ><span class="pt-2">ประกาศรับสมัคร</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="9" sm="9">
            <input
              type="file"
              accept="application/pdf,"
              id="file"
              ref="form.download"
              v-on:change="handleFileUpload('form.download')"
            />
            <v-btn small @click="submitFile('form.download')">upload</v-btn>
          </v-col>
          <v-col cols="12" md="2" sm="2" v-if="form.download"></v-col>
          <v-col cols="12" md="9" sm="9" class="text-left" v-if="form.download">
            <p
              style="color: #4caf50; cursor: pointer"
              @click="onClickFile(form.download)"
            >
              ดูไฟล์
            </p>
          </v-col>
        </v-row>

        <v-row align="center" justify="end">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <v-btn @click="submit()" dark color="#2AB3A3">บันทึก</v-btn>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode, Encode } from "@/services";
import { VueEditor } from "vue2-editor";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
    VueEditor,
  },
  data() {
    return {
      form: {
        title: null,
        download: null,
      },

      getToday: new Date().toLocaleString(),
      today: "",
      status: "เปิดใช้งาน",
      statusItems: [
        { id: 1, name: "เปิดใช้งาน" },
        {
          id: 2,
          name: "ปิดใช้งาน",
        },
        {
          id: 3,
          name: "ฉบับร่าง",
        },
      ],

      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
      loading: false,
    };
  },
  watch: {},
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.setDate();
  },
  methods: {
    setDate() {
      const current = new Date();
      const date = `${current.getDate()}/${
        current.getMonth() + 1
      }/${current.getFullYear()}`;
      this.today = date;
    },

    cancel() {
      this.$router.push("ManageFileDownload");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          name_th: this.name_th,
          name_en: this.name_en,
          short_desc_th: this.short_desc_th,
          short_desc_en: this.short_desc_en,
          semesterCourse: this.semesterCourse,
          qualification_th: this.qualification_th,
          qualification_en: this.qualification_en,
          seleaction_method_th: this.seleaction_method_th,
          seleaction_method_en: this.seleaction_method_en,
          supposed_budget: this.supposed_budget,
          register_schedule: this.register_schedule,
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/fileDownloadStds`,
          // data,
          this.form,
          auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างใหม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageFileDownload");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    async handleFileUpload(questionNo) {
      // this.data[questionNo] = this.$refs[questionNo].files[0];
      // console.log("file", this.data[questionNo]);
    },
    onClickFile(val) {
      window.open(val, "_blank");
    },
    async submitFile(questionNo) {
      console.log("this.$refs[questionNo]", this.$refs[questionNo]);
      if (this.$refs[questionNo]) {
        this.loading = true;
        if (this.$refs[questionNo].files[0]) {
          let formData = new FormData();
          formData.append("files", this.$refs[questionNo].files[0]);
          formData.append("filename", this.$refs[questionNo].files[0].name);
          formData.append("type", questionNo);
          // formData.append("userId", this.userId);
          console.log("formData", formData);
          const auth = {
            headers: {
              "Content-Type": "multipart/form-data",
              // Authorization: `Bearer` + " " + this.checkuser.token,
            },
          };
          console.log("auth", auth);
          this.uploaded = true;
          const response = await this.axios.post(
            `https://gscm-service.yuzudigital.com/files/formData`,
            formData,
            auth
          );
          if (questionNo == "form.download") {
            this.form.download = response.data.data.path;
          }
          console.log("SUCCESS!!");
          console.log("response", response);
        }
        this.loading = false;
      }
    },
  },
};
</script>
